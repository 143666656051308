var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-card",
            {
              staticStyle: { "border-radius": "5px" },
              attrs: { outlined: "", elevation: "1", "min-width": "100%" },
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "mx-0 pr-3" },
                        [
                          _c(
                            "v-avatar",
                            { staticStyle: { background: "#E3E3E3" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold primary--text",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.$store.getters.getFname
                                          .toString()
                                          .charAt(0)
                                          .toUpperCase()
                                      ) +
                                      " " +
                                      _vm._s(
                                        this.$store.getters.getLname
                                          .toString()
                                          .charAt(0)
                                          .toUpperCase()
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { staticClass: "px-0 mx-0" },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-row", [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold text-h6" },
                                  [
                                    _vm._v(
                                      " Welcome back, " +
                                        _vm._s(this.$store.getters.getFname) +
                                        " " +
                                        _vm._s(this.$store.getters.getLname) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-row", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "grey--text",
                                    staticStyle: { "font-size": "14px" },
                                  },
                                  [
                                    _vm._v(
                                      " We're very happy to see you again on your personal dashboard "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title"),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          staticStyle: { background: "#33BDF1" },
                          on: { click: _vm.redirectPersonalTask },
                        },
                        [_vm._v(" View Task ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-0 pr-3", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { "border-radius": "5px", overflow: "auto" },
                  attrs: { outlined: "", elevation: "1", height: "500px" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "d-flex justify-center font-weight-bold" },
                    [_vm._v(" Announcement ")]
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "mx-5",
                      staticStyle: {
                        border: "1px solid",
                        "border-color": "#E3E6EB",
                      },
                      attrs: { height: "83%" },
                    },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mx-2",
                              attrs: { size: "10", color: "primary" },
                            },
                            [_vm._v(" bi-circle-fill ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "font-weight-bold text-h7" },
                            [_vm._v(" Upcoming Modules ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "grey--text",
                          staticStyle: { left: "5%" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-row", [
                                _c("span", [_vm._v(" 1. Refund ")]),
                              ]),
                              _c("v-row", [
                                _c("span", [_vm._v(" 2. Freebies ")]),
                              ]),
                              _c("v-row", [_c("span", [_vm._v(" 3. HRDC ")])]),
                              _c("v-row", [
                                _c("span", [_vm._v(" 4. First joiner ")]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "px-0", attrs: { cols: "12", sm: "8" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { "border-radius": "5px", overflow: "auto" },
                  attrs: { outlined: "", elevation: "1", height: "500px" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "d-flex justify-center font-weight-bold" },
                    [_vm._v(" Performance Summary - Filter Years ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "px-5" },
                    [_c("PagePerformanceChart")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-0 ", attrs: { cols: "12", sm: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "px-5",
                  attrs: { elevation: "1", outlined: "" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "d-flex justify-center font-weight-bold pb-0 mb-0",
                    },
                    [_vm._v(" Top 10 Trending Video - Filter Days ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "px-5" },
                    [_c("PageTopVideosChart")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }